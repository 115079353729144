<template>
  <span
    v-if="!creating"
  >
    <b-button
      @click="() => createRepairList(true)"
    >
      {{ $t('failures.repairListForm.generate_empty_repair_list') }}
    </b-button>
    <b-field>
      <p class="control">
        <b-button
          @click="() => createRepairList()"
        >
          {{ $t('failures.repairListForm.generate_repair_list') }}
        </b-button>
      </p>
      <p class="control">
        <b-dropdown
          v-model="locale"
        >
          <b-button
            slot="trigger"
            class="button"
            icon-left="menu-down"
          >
            {{ locale }}
          </b-button>

          <b-dropdown-item value="en">English</b-dropdown-item>
          <b-dropdown-item value="cs">Česky</b-dropdown-item>
        </b-dropdown>
      </p>
    </b-field>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { random, includes, pick, concat, forEach, get } from 'lodash'


export default {
  props: {
    filePath: {
      type: String,
      required: true
    },
    failure: {
      type: Object,
      required: true
    },
    node: {
      type: Object,
      required: true
    },
    parts: {
      type: Array,
      required: true
    },
    beforeListCreating: {
      type: Function,
      default: () => { return }
    }
  },
  data() {
    return {
      creating: false,
      locale: 'en'
    }
  },
  computed: {

  },
  async created() {
    await store.dispatch('locales/openDBChannel', { where: [['id', '==', 'en']] })
  },
  methods: {
    async createRepairList (empty = false) {
      const settings = {
        empty
      }

      this.creating = true

      const filename = this.$t(empty ? 'components.createRepairListButton.empty_repair_list' : 'components.createRepairListButton.repair_list', this.locale)

      this.beforeListCreating(settings)

      const data = {
        node: this.node,
        parts: this.parts,
        failure: this.failure,
        settings
      }

      console.log({ data })

      const response = await fetch('https://us-central1-rams-e6d4f.cloudfunctions.net/requests-createPdf', {
        method: 'POST',
        body: JSON.stringify({
          filename: `${this.filePath}/${filename}`,
          data,
          template: 'RepairList.vue',
          locale: empty ? 'cs' : this.locale
        })
      })
      this.creating = false
      this.$emit('created', settings)
    }
  }
}
</script>


