<template>
  <div
    v-if="doc"
  >
    <div class="columns">
      <div class="column">
        <div class="buttons is-pulled-right">
          <b-button
            v-if="user.role === 'admin'"
            type="is-danger"
            icon-left="delete"
            @click="remove"
          >
            {{ $t('common.delete') }}
          </b-button>
          <b-button
            icon-left="arrow-up"
            @click="goToFailure"
          >
            {{ $t('common.navigation.go_to_failure') }}
          </b-button>
          <template
            v-if="canUpdateDocument"
          >
            <create-repair-list-button
              :file-path="`failureNodes/${doc.id}`"
              :node="doc"
              :parts="parts"
              :failure="failure"
              :before-list-creating="beforeListCreating"
              @created="repairListCreated"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="is-clearfix" />


    <div class="columns">
      <div
        v-if="canUpdateDocument"
        class="column"
      >
        <a-select
          field="part"
          options="parts"
          :label="$t('parts.ref.select')"
          :to-pick="['id', 'name', 'supplier.id', 'supplier.name']"
        />

        <div class="columns">
          <div class="column">
            <a-input
              field="number"
              :label="$t('failureNodes.form.number.label')"
              :placeholder="$t('failureNodes.form.number.placeholder')"
              update-on-blur
            />
          </div>
          <div class="column">
            <a-input
              field="replacement.number"
              :label="$t('failureNodes.form.replacement.label')"
              :placeholder="$t('failureNodes.form.replacement.placeholder')"
            />
          </div>
        </div>

        <a-select
          field="state"
          :label="$t('common.state')"
          :options="stateOptions"
        />

        <div class="columns">
          <div class="column">
            <a-input
              field="name"
              :required="!doc.parent"
              :label="$t('failures.ref.name')"
              type="textarea"
            />
          </div>
          <div class="column">
            <a-input
              field="resolution"
              :label="$t('common.resolution')"
              type="textarea"
            />
          </div>
        </div>


        <b-field>
          <a-input
            field="service.inspection"
            :label="$t('failures.props.service.inspection')"
            type="number"
          />

          <a-input
            field="service.repair"
            :label="$t('failures.props.service.repair')"
            type="number"
          />

          <a-input
            field="service.testing"
            :label="$t('failures.props.service.testing')"
            type="number"
          />
        </b-field>

        <a-input
          field="notes"
          :label="$t('common.notes')"
          type="textarea"
        />

        <a-input
          field="visual_inspection"
          :label="$t('failureNodes.form.visual_inspection')"
          type="textarea"
        />
      </div>
      <div
        v-else
        class="column"
      >
        <a-value
          field="part.name"
          :label="$t('parts.ref.name')"
        />

        <a-value
          field="part.supplier.name"
          :label="$t('suppliers.sg')"
        />

        <span v-html="doc.number ? `<i>Number:</i> <code>${doc.number}</code><br/>` : ''" />


        <a-value
          field="replacement.number"
          :label="$t('failureParts.columns.replacement.number')"
        />
        <a-value
          field="state"
        />

        <a-value
          field="name"
        />

        <a-value
          field="resolution"
        />

        <a-value
          field="service.inspection"
          :label="$t('failures.props.service.inspection')"
        />

        <a-value
          field="service.repair"
          :label="$t('failures.props.service.repair')"
        />

        <a-value
          field="service.testing"
          :label="$t('failures.props.service.testing')"
        />

        <a-value
          field="visual_inspection"
        />
      </div>
      <div class="column is-one-quarter">
        <contacts
          v-if="keys(doc.team).length"
          :header="$t('failures.service_team')"
          collection-path="team"
        />
        <files
          :key="filesKey"
        />

        <parent />

        <failure-state
          :object-doc="doc.failure"
          object-module="failures"
          :editable="false"
        />
        <failure-vehicle
          :object-doc="doc.failure"
          object-module="failures"
          :editable="false"
        />
      </div>
    </div>

    <add-button />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { random, includes, concat, keys } from 'lodash'
import Files from '@/components/Files'
import Contacts from '@/components/Contacts/Contacts'
import Parent from './FailureNode/Parent'
import FailureState from './Failure/State'
import FailureVehicle from './Failure/Vehicle'
import AddButton from './FailureNode/AddButton'
import CreateRepairListButton from '@/components/CreateRepairListButton'

export default {
  components: {
    Files,
    Contacts,
    Parent,
    FailureState,
    FailureVehicle,
    AddButton,
    CreateRepairListButton
  },
  data() {
    return {
      filesKey: random(1,99999)
    }
  },
  computed: {
    ...mapGetters({
      failure: 'failures/currentFailure',
      allFailureParts: 'failureParts/currentFailureItems',
      allFailureNodes: 'failureNodes/currentFailureItems'
    }),
    stateOptions() {
      return _.pull(_.clone(this.$store.getters["failureParts/fields"].state.options), 'received')
    },
    parts() {
      return _.concat(_.filter(this.allFailureNodes, item => item.parent && item.parent.id === this.doc.id), _.filter(this.allFailureParts, item => item.parent && item.parent.id === this.doc.id))
    },
    fileNames() {
      return _.map(this.files, 'name')
    },
    filesPath() {
      return `failureNodes/${this.doc.id}`
    },
    canUpdateDocument () {
      return this.$store.getters[`failureNodes/canUpdateDocument`](this.doc)
    },
    rowClass () {
      return this.$store.getters[`${this.module}/rowClass`](this.doc)
    }
  },
  async created() {
    await this.$store.dispatch('currentDoc/loadDoc')
    await this.$store.dispatch('failures/fetchById', this.doc.failure.id)
  },
  methods: {
    goToFailure() {
      this.$router.push({ name: 'failure', params: { id: this.doc.failure.id } })
    },
    keys,
    includes,
    beforeListCreating(settings) {
      const { empty } = settings

      if(!empty) {
        this.$store.dispatch('failureNodes/set', {
          id: this.doc.id,
          generated_by: _.pick(this.user, ['id', 'name']),
          generated_at: new Date()
        })
      }
    },
    repairListCreated (settings) {
      this.filesKey = random(1, 99999)
    },
    remove () {
      this.$store.dispatch('failureNodes/delete', this.doc.id)
      this.$router.go(-1)
    }
  }
}
</script>
